import React, {Component} from 'react';
import Helmet from 'react-helmet'
import lib from 'library'

import TitleBar from 'components/TitleBar.js'
import MdInsertDriveFile from 'react-icons/lib/md/insert-drive-file';
import Layout from "components/layout"

export default class App extends Component {
  render(){

    const lang = lib.lc.currentLang(this.props.location.pathname)

    return(

<Layout pathname={this.props.location.pathname}>
          <div >
            <Helmet
              title={lc[lang].pagename}
              meta={[
                { name: 'description', content: '冠信控股有限公司成立於2014年，主要業務是代工生產箱包、背包和旅行配件。' },
                { name: 'keywords', content: '冠信控股有限公司, 冠信, 行李箱, 背包, 旅行配件' },
              ]}
            />

          <div style={{display: 'flex', height: 800, flexDirection: 'column', justifyContent: 'center'}}>
                <MdInsertDriveFile style={{alignSelf: 'center'}} color={lib.st.COLOR_THEME} size={55} />
                <TitleBar title={lc.zh.companyname + '  ' + lc.en.companyname} />

          </div>


          </div>
</Layout>
    )
  }
}


const lc = {
  en:{
    pagename: 'Kun Shung Holdings Limited',
    companyname: 'Page not found',
  },
  zh:{
    pagename: '冠信控股有限公司',
    companyname: '沒有此頁',
  }
}
